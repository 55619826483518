<template>
  <div class="topbar-container">
    <!-- header -->
    <div class="header">
      <div class="headerTop flexJsbAc">
        <div class="toggle-btn">
          <i class="el-icon-s-fold" @click="toggleSidebar"></i>
          <BreadCrumd></BreadCrumd>
        </div>
        <div class="passwordExpiresIn" @click="changePassword" v-if="passwordExpiresInShow">
          <!-- <span>{{ userInfo.passwordExpiresMessage }}dwde</span> -->
          <vue-seamless-scroll
            :data="passwordExpiresMessageList"
            class="seamless-warp"
            :class-option="classOption"
          >
            <span
              class="content"
              v-for="(item, index) in passwordExpiresMessageList"
              :key="index"
            >
              {{ item }}
            </span>
          </vue-seamless-scroll>
        </div>
        <div class="header-right flexJfAc">
          <div class="operationManual">
             <el-button @click="openOperationManual">系统操作指南，点我</el-button>
          </div>
          <div class="btn-fullscreen" @click="handleFullScreen">
            <el-tooltip
              effect="dark"
              :content="fullscreen ? `取消全屏` : `全屏`"
              placement="bottom"
            >
              <i class="el-icon-rank"></i>
            </el-tooltip>
          </div>

          <el-dropdown>
            <span class="el-dropdown-link" v-if="userInfo">
              <img class="user-img" :src="userInfo.avatar" alt="" />
              <span class="user-text">{{ userInfo.realName }}</span>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item @click.native="goPersonalCenter"
                >个人中心</el-dropdown-item
              >
              <el-dropdown-item @click.native="signOut()"
                >退出登录</el-dropdown-item
              >
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
    </div>
    <!-- 标签页 -->
    <TagsView></TagsView>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import BreadCrumd from "./breadCrumd";
import TagsView from "./tagsView";
import { logout } from "@/api/login";
import vueSeamlessScroll from "vue-seamless-scroll";
export default {
  data() {
    return {
      userInfo: null,
      fullscreen: false,
      realName: null,
      avatar: null,
      passwordExpiresMessageList: [],
      passwordExpiresInShow: false,
    };
  },

  components: {
    BreadCrumd,
    TagsView,
    vueSeamlessScroll,
  }, 
  created() {
    this.passwordExpiresInChange(); 
  },
  mounted() { 
  },
  computed: {
    ...mapState({
      isCollapse: (state) => state.common.isCollapse,
    }),
    classOption() {
      return {
        step: 0.6, // 数值越大速度滚动越快
        limitMoveNum: 1, // 开始无缝滚动的数据量
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 2, // 0向下 1向上 2向左 3向右
        openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 43, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 20, // 单步运动停止的时间(默认值1000ms)
        isSingleRemUnit: true, //singleHeight and singleWidth是否开启rem度量
      };
    },
  },
  methods: {
    openOperationManual(){
      this.$router.push("/systemOperationManual");
    },
    changePassword(){
      this.$router.push("/personalCenter");
    },
    passwordExpiresInChange() {
      this.userInfo = JSON.parse(window.localStorage.getItem("userInfo"));
      if (this.userInfo.passwordExpiresIn && this.userInfo.passwordExpiresIn > 0) {
        this.passwordExpiresInShow = true;
        this.passwordExpiresMessageList.push(
          this.userInfo.passwordExpiresMessage
        );
      }
    },
    ...mapActions(["toggleSidebar"]),
    // 全屏事件
    handleFullScreen() {
      let element = document.documentElement;
      if (this.fullscreen) {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen();
        }
      } else {
        if (element.requestFullscreen) {
          element.requestFullscreen();
        } else if (element.webkitRequestFullScreen) {
          element.webkitRequestFullScreen();
        } else if (element.mozRequestFullScreen) {
          element.mozRequestFullScreen();
        } else if (element.msRequestFullscreen) {
          // IE11
          element.msRequestFullscreen();
        }
      }
      this.fullscreen = !this.fullscreen;
    },
    goPersonalCenter() {
      this.$router.push("/personalCenter");
    },
    //退出
    signOut() {
      logout().then((response) => {
        this.$message({ type: "success", message: "退出成功!" });
        window.localStorage.clear();
        this.$router.push("login");
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.topbar-container {
  width: 100%;
  height: 87px;
  background-color: #fff;
  .header {
    height: 50px;
    line-height: 50px;
    border-bottom: 1px solid #ccc;
    padding: 0 20px;
    .headerTop {
      .toggle-btn {
        i {
          margin-right: 10px;
        }
      }
      .passwordExpiresIn {
        cursor: pointer;
      }
      .seamless-warp {
        width: 260px;
        height: 50px;
        overflow: hidden;
        .content {
          margin-right: 20px;
          color: #f56c6c;
          font-size: 14px;
          letter-spacing: 2px;
        }
      }

      .header-right {
        height: 50px;
        line-height: 50px;
        .operationManual{
          margin-right: 20px;
          .el-button{
            padding: 5px 10px;
            font-size: 12px;
          }
        }
        .btn-fullscreen {
          display: inline-block;
          transform: rotate(45deg);
          margin-right: 20px;
          font-size: 24px;
          vertical-align: top;
        }
        .el-dropdown-link {
          display: inline-block;
          height: 50px;
          line-height: 50px;
          padding: 0 5px;
          .user-img {
            width: 45px;
            height: 45px;
            border-radius: 50%;
            vertical-align: top;
          }
          .user-text {
            display: inline-block;
            padding-left: 5px;
            vertical-align: top;
          }
        }
        .el-dropdown-link:hover {
          background-color: #f3f3f3;
        }
      }
    }
  }
}
</style>