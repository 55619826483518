/*
 * @Description: 
 * @Author: transparent
 * @Date: 2021-12-31 11:17:14
 * @LastEditTime: 2022-03-08 14:57:13
 * @LastEditors: transparent
 * @FilePath: /hermes-web/src/api/user.js
 */
import request from '@/utils/request'

export function getUserInfo() {
  return request({
    url: '/system/user/current',
    method: 'get',
  })
}

export function getUserInfoById(uid) {
  return request({
    url: '/admin/system/user/find',
    method: 'post',
    data: {uid}
  })
}
export function getownResourceList() {//前权限接口
  return request({
    url: '/admin/role/ownResourceList',
    method: 'post',
    data: {}
  })
}

//获取权限
// {
//   "roleCodes":[
//       "ir"
//   ],
//   "userId":"u00000011"
// }
// “roleCodes”: 角色Code数组
// “userId”: 用户ID
// 啥都不传，则为当前登陆人的
// 三选一即可
export function getPermissionInfo() {//现权限接口
  return request({
    url: '/admin/menu/findPermissionInfo',
    method: 'post',
    data: {}
  })
}


export function bindDingTalk(param) {
  return request({
    url: '/system/user/bind/ding-talk',
    method: 'post',
    data: param
  })
}