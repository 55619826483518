<template>
  <div class="app-main-container" :class="{ 'collapse-main': isCollapse, 'lock-scroll': lockScroll }">
    <transition name="fade-transform" mode="out-in">
      <keep-alive>
        <router-view></router-view>
      </keep-alive>
    </transition>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState({
      isCollapse: (state) => state.common.isCollapse,
      lockScroll: (state) => state.common.lockScroll,
    }),
  },
};
</script>

<style lang="scss" scoped>
.app-main-container {
  position: absolute;
  width: calc(100% - 185px);
  height: calc(100% - 92px);
  margin: 5px;
  padding: 10px;
  background-color: #fff;
  transition: width 0.28s;
}
.collapse-main {
  width: calc(100% - 84px);
}
.lock-scroll {
  overflow: hidden !important;
}
</style>
