<template>
  <div class="tags" v-if="showTags">
    <div class="scrollbarX">
      <div class="tags-li flexJsbAc" v-for="(item,index) in tagsList" :class="{'active': isActive(item.path)}"
           :key="index">
        <router-link :to="item.path" class="tags-li-title">
          {{ item.title }}
        </router-link>
        <span class="tags-li-icon" @click="closeTags(index)"><i class="el-icon-close"></i></span>
      </div>
    </div>
    <!-- 其他操作按钮 -->
    <div class="tags-close-box">
      <el-dropdown @command="handleTags">
        <el-button size="mini">
          <i class="el-icon-arrow-down el-icon--right"></i>
        </el-button>
        <el-dropdown-menu size="small" slot="dropdown">
          <el-dropdown-item command="other">关闭其他</el-dropdown-item>
          <el-dropdown-item command="all">关闭所有</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tagsList: []
    }
  },
  computed: {
    showTags() {
      return this.tagsList.length > 0;
    }
  },
  watch: {
    // 对router进行监听，每当访问router时，对tags的进行修改
    $route(newValue) {
      this.setTags(newValue);
    }
  },
  created() {
    // 第一次进入页面时，修改tag的值
    this.setTags(this.$route);
  },
  methods: {
    isActive(path) {
      return path === this.getPath();
    },
    // 关闭单个标签
    closeTags(index) {
      const delItem = this.tagsList.splice(index, 1)[0];
      const item = this.tagsList[index] ? this.tagsList[index] : this.tagsList[index - 1];
      if (item) {
        delItem.path === this.getPath() && this.$router.push(item.path);
      } else {
        this.$router.push('/');
      }
    },
    // 关闭全部标签
    closeAll() {
      this.tagsList = [];
      this.$router.push('/');
    },
    // 关闭其他标签
    closeOther() {
      const path = this.getPath();
      const curItem = this.tagsList.filter(item => {
        return item.path === path;
      })
      this.tagsList = curItem;
    },
    // 设置标签
    setTags(route) {
      const path = this.getPath(route.fullPath);
      const isExist = this.tagsList.some(item => {
        return item.path === path;
      });
      !isExist && this.tagsList.push({
        title: route.meta.title,
        path: path,
        name: route.matched[1].components.default.name
      })
    },

    getPath(fullPath) {
      if (!fullPath){
        fullPath = this.$route.fullPath;
      }
      let path = '';
      if (fullPath.indexOf("?") > 0) {
        path = fullPath.substring(0, fullPath.indexOf("?"));
      } else {
        path = fullPath;
      }
      return path;
    },
    // 当关闭所有页面时隐藏
    handleTags(command) {
      command === 'other' ? this.closeOther() : this.closeAll();
    }
  }
}

</script>


<style>
.tags {
  position: relative;
  height: 40px;
  overflow: hidden;
  background: #fff;
  padding-right: 120px;
  border-bottom: 1px solid #d8dce5;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .12), 0 0 3px 0 rgba(0, 0, 0, .04);
}

.tags .scrollbarX {
  box-sizing: border-box;
  /* width: 100%; */
  height: 100%;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  padding: 0 0px 0 20px;
  /* width: 50rem;
  overflow-x: scroll; */
}

.tags-li {
  /* float: left; */
  margin: 3px 5px 2px 3px;
  border-radius: 3px;
  font-size: 12px;
  overflow: hidden;
  cursor: pointer;
  height: 23px;
  line-height: 23px;
  border: 1px solid #e9eaec;
  background: #fff;
  padding: 0 2px 0 4px;
  vertical-align: middle;
  color: #666;
  -webkit-transition: all .3s ease-in;
  -moz-transition: all .3s ease-in;
  transition: all .3s ease-in;
  width: 120px !important;
  /* text-align: center; */
}

.tags-li:not(.active):hover {
  background: #f8f8f8;
}

.tags-li-title {
  float: left;
  width: 99px;
  overflow: hidden;
  white-space: nowrap;
  /* text-overflow: ellipsis; */
  /* margin-right: 5px; */
  color: #666;
  text-align: center;
}

.tags-li-icon {

}

.tags-li.active {
  color: #fff;
  border: 1px solid #10B9D3;
  background-color: #10B9D3;
}

.tags-li.active .tags-li-title {
  color: #fff;
}

.tags-close-box {
  position: absolute;
  right: 0;
  top: 0px;
  box-sizing: border-box;
  padding-top: 1px;
  text-align: center;
  z-index: 10;
}

</style>
