/*
 * @Description: 
 * @Author: transparent
 * @Date: 2021-12-31 11:17:14
 * @LastEditTime: 2022-04-22 09:55:48
 * @LastEditors: transparent
 * @FilePath: /hermes-web/src/api/externalData.js
 */
import request from '@/utils/request';  
//获取日程列表
export function getExternalData(param){
    return request({
        url: '/admin/data/external/execute',
        method: 'post',
        data: param 
    })
}

// {
//     "providerName":"tb.hot-word",
//     其他参数
// }
// 淘宝卖家店铺基本信息查询V1
// 提供者：tb.shop.info
// 参数名：
// sellerNickname
// sellerId
// shopId



// 淘宝整店商品列表查询V1
// 提供者：tb.shop.product-list
// 参数名：
// shopId
// keywords
// pageNum
// pageSize
// sort
// startPrice
// endPrice


// 淘宝热词
// 提供者：tb.hot-word
// 参数名：
// keywords


//抖音-商品搜索
// providerName： dy.goods.search
// cursor 查第一页时传0，查找下一页时cursor传本次返回的值

//抖音-综合搜索
// 固定值：dy.general.search
// cursor 查第一页时传0，查找下一页时cursor传本次返回的值


export function getShopSelectList(param){
    return request({
        url: '/admin/online-shop/select-list',
        method: 'post',
        data: param 
    })
}



 