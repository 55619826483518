/*
 * @Description:
 * @Author: transparent
 * @Date: 2021-01-12 21:39:19
 * @LastEditTime: 2022-01-19 17:49:56
 * @LastEditors: transparent
 * @FilePath: /hermes-web/src/utils/request.js
 */
import axios from 'axios'
import { Message, MessageBox } from 'element-ui'
import uuidv4 from '@/common/uuidUtil'
import { PLATFORM_CODE, TOKEN_KEY, NO_LOGIN, INVALID_TOKEN } from '@/common/constants'

// 创建axios实例
const service = axios.create({
  // api的base_url
  baseURL: process.env.VUE_APP_BASE_API,
  // 请求超时时间
  timeout: 20000
});

// request拦截器
service.interceptors.request.use(config => {
  if (window.localStorage.getItem(TOKEN_KEY)) {
    config.headers['Authorization'] = window.localStorage.getItem(TOKEN_KEY);
  }
  config.headers['visitorId'] = window.localStorage.getItem("visitorId") ? window.localStorage.getItem("visitorId") : uuidv4();
  config.headers['platform'] = PLATFORM_CODE;
  return config
}, error => {
  // Do something with request error
  return Promise.reject(error)
});

// response拦截器
service.interceptors.response.use(
  response => {
    const res = response.data;
    if (res.success !== true) {
      if (res.code == NO_LOGIN || res.code == "A012S101") {
        window.localStorage.clear();
        //接口登录提示
        MessageBox.confirm('请先登录', '登录提醒', {
          confirmButtonText: '登录',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          // 为了重新实例化vue-router对象 避免bug
          // location.reload()
          window.location.href = window.location.origin + '/login'
          // this.$router.push("/login");
        })
      } else if (res.code == INVALID_TOKEN || res.code == "A012S102") {
        window.localStorage.clear();
        //接口登录提示
        MessageBox.confirm('你的登录已失效登出，请重新登录', '登录提醒', {
          confirmButtonText: '重新登录',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          // 为了重新实例化vue-router对象 避免bug
          // location.reload()
          window.location.href = window.location.origin + '/login'


          // this.$router.push("/login");
        })
      } else {
        //接口其他错误信息
        Message({
          message: res.message,
          type: 'error',
          duration: 0, //设置0为不会被自动关闭，默认为 3000 毫秒。
          showClose: true,
        });
        return Promise.reject(res.message)
      }
    } else {
      return response.data
    }
  },
  error => {
    //HTTP响应错误
    Message({
      message: error,
      type: 'error',
      duration: 0, //设置0为不会被自动关闭，默认为 3000 毫秒。
      showClose: true,

    });
    return Promise.reject(error)
  }
);

export default service
