<!--
 * @Description: 
 * @Author: transparent
 * @Date: 2021-01-12 21:39:19
 * @LastEditTime: 2022-01-25 11:23:46
 * @LastEditors: transparent
 * @FilePath: /hermes-web/src/views/layout/TopBar/breadCrumd.vue
-->
<template>
  <!-- <div class="all"> -->
    <el-breadcrumb class="app-breadcrumb" separator="/">
      <transition-group name="breadcrumb">
        <el-breadcrumb-item v-for="(item, index) in levelList" :key="item.path">
          <span
            v-if="
              item.redirect === 'noredirect' || index == levelList.length - 1
            "
            class="no-redirect"
            >{{ item.meta.title }}</span
          >
          <a v-else @click.prevent="handleLink(item)">{{ item.meta.title }}</a>
        </el-breadcrumb-item>
      </transition-group>
    </el-breadcrumb>
    <!-- 标签页 -->
    <!-- <TagsView></TagsView> -->
  <!-- </div> -->
</template>

<script>
import pathToRegexp from "path-to-regexp";
import TagsView from "./tagsView";

export default {
  data() {
    return {
      levelList: null,
    };
  },
  components: {
    TagsView,
  },
  watch: {
    $route() {
      this.getBreadcrumb();
    },
  },
  created() {
    this.getBreadcrumb();
  },
  methods: {
    getBreadcrumb() {
      var token = window.localStorage.getItem("tokenId"); //获取浏览器缓存的用户信息
      if (token) {
        let matched = this.$route.matched.filter((item) => {
          if (item.name) {
            return true;
          }
        });

        const first = matched[0];
        // if (
        //   first &&
        //   first.name.trim().toLocaleLowerCase() !== "home".toLocaleLowerCase()
        // ) {
        //   matched = [{ path: "/home", meta: { title: "首页" } }].concat(
        //     matched
        //   );
        // }
        this.levelList = matched;
      }

      // console.log(matched)
    },
    pathCompile(path) {
      const { params } = this.$route;
      var toPath = pathToRegexp.compile(path);
      return toPath(params);
    },
    handleLink(item) {
      const { redirect, path } = item;
      if (redirect) {
        this.$router.push(redirect);
        return;
      }
      this.$router.push(this.pathCompile(path));
    },
  },
};
</script>

<style lang="scss" scoped>
.all {
    // display: inline-block;
  
 
}
 .app-breadcrumb.el-breadcrumb {
    display: inline-block;
    font-size: 14px;
    .no-redirect {
      color: #97a8be;
      cursor: text;
    }
  }
</style>