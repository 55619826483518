/*
 * @Description:
 * @Author: transparent
 * @Date: 2022-03-09 17:59:18
 * @LastEditTime: 2022-03-15 11:43:42
 * @LastEditors: transparent
 * @FilePath: /hermes-web/src/utils/btn-permission.js
 */
import Vue from "vue";
import store from "@/store";
import { getPermissionInfo } from "@/api/user";

// 是否有权限
const hasPermission = userPermission => {
  let userPermissionList = Array.isArray(userPermission)
    ? userPermission
    : [userPermission];
  let permissionList = store.state.permissionInfo
    ? store.state.permissionInfo.permissions
    : null;

  return new Promise(resolve => {
    if (permissionList) {
      let permission = userPermissionList.some(e => permissionList.includes(e));
      resolve(permission);
    } else {
      let time = 100;
      if (time > 0) {
        time = 0;
        getPermissionInfo()
          .then(response => {
            if (response.success) {
              // console.info(userPermission);
              let permissionInfo = response.data;
              store.commit("setPermissionInfo", permissionInfo);
              permissionList = permissionInfo.permissions;
              let permission = userPermissionList.some(e =>
                permissionList.includes(e)
              );
              resolve(permission);
            } else {
              resolve(false);
            }
          })
          .catch(_ => {
            resolve(false);
          });
      }
    }
  });
};

// 指令
Vue.directive("permission", {
  inserted: (el, binding, vnode) => {
    hasPermission(binding.value).then(permission => {
      if (!permission) {
        el.parentNode.removeChild(el);
        // inserted 可以找到el的父级， bind找不到，然后removeChild报错
      }
    });
  }
});

// 全局判断方法
Vue.prototype.$_has = hasPermission;
