import request from '@/utils/request'

export function login(username, password) {
  return request({
    url: '/system/login',
    method: 'post',
    data: {
      username,
      password
    }
  })
}

export function logout() {
  return request({
    url: '/system/logout',
    method: 'get'
  })
}

export function getResetPasswordVerifyCode(phoneNumber) {
  return request({
    url: '/sms/verify-code/send?phoneNumber=' + phoneNumber + '&bizType=system:user:password:reset',
    method: 'get'
  })
}

export function changePassword(param) {
  return request({
    url: '/system/user/password/change',
    method: 'post',
    data: param
  })
}

