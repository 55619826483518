export default {

  parseDate(date) {
    if (typeof (date) === 'number') {
      date = new Date(date);
    }
    if (typeof (date) === 'string') {
      if (/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\+\d{2}:\d{2}$/.test(date)) {
        date = new Date(date);
      }
      if (/^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}$/.test(date)) {
        date = new Date(date);
      }
      if (/^\d{4}-\d{2}-\d{2}$/.test(date)) {
        date = new Date(date + ' 00:00:00');
      }
    }
    return date;
  },
  //  * 获取当前月期号
  //  * 返回格式: YYYY-mm
  //  * /
  getCurrentMonthIssue(date) {
    let month = parseInt(date.getMonth() + 1);
    if (month < 10) {
      month = '0' + month
    }
    return date.getFullYear() + "-" + month;
  },

  //  获取周的第一天
  //   返回格式: YYYY-mm-dd
  //   例子: 当日为: 2020-11-27
  // 返回日期为: 2020-11-23
  //
  getWeekFirstDay(date) {
    let day = date.getDay();
    if (day === 0) {
      day = 7;
    }
    let weekFirstDay = new Date(date - (day - 1) * 86400000);
    let firstMonth = Number(weekFirstDay.getMonth()) + 1

    if (firstMonth < 10) {
      firstMonth = '0' + firstMonth
    }
    let weekFirstDays = weekFirstDay.getDate();
    if (weekFirstDays < 10) {
      weekFirstDays = '0' + weekFirstDays;
    }
    return weekFirstDay.getFullYear() + '-' + firstMonth + '-' + weekFirstDays;
  },


  // 获取周的最后一天
  //   返回格式: YYYY-mm-dd
  //  例子: 当日为: 2020-11-27
  //  返回日期为: 2020-11-29
  //
  getWeekLastDay(date) {
    let day = date.getDay();
    if (day === 0) {
      day = 7;
    }
    let weekFirstDay = new Date(date - (day - 1) * 86400000)
    let weekLastDay = new Date((weekFirstDay / 1000 + 6 * 86400) * 1000)
    let lastMonth = Number(weekLastDay.getMonth()) + 1
    if (lastMonth < 10) {
      lastMonth = '0' + lastMonth
    }
    let weekLastDays = weekLastDay.getDate();
    if (weekLastDays < 10) {
      weekLastDays = '0' + weekLastDays;
    }
    return weekLastDay.getFullYear() + '-' + lastMonth + '-' + weekLastDays;
  },


  //   获取当前月的第一天
  //返回格式: YYYY-mm-dd
  // 例子: 当日为: 2020-11-27
  //  返回日期为: 2020-11-01
  //
  getCurrentMonthFirstDay() {
    let date = new Date();
    date.setDate(1);
    let month = parseInt(date.getMonth() + 1);
    let day = date.getDate();
    if (month < 10) {
      month = '0' + month
    }
    if (day < 10) {
      day = '0' + day
    }
    return date.getFullYear() + '-' + month + '-' + day;
  },

  // 获取当前月的最后一天
  // 返回格式: YYYY-mm-dd
  // 例子: 当日为: 2020-11-27
  //  返回日期为: 2020-11-30
  getCurrentMonthLastDay() {
    let date = new Date();
    return this.getMonthLastDay(date);
  },

  // 获取指定日期的当月最后一天
  getMonthLastDay(date) {
    let currentMonth = date.getMonth();
    let nextMonth = ++currentMonth;
    let nextMonthFirstDay = new Date(date.getFullYear(), nextMonth, 1);
    let oneDay = 1000 * 60 * 60 * 24;
    let lastTime = new Date(nextMonthFirstDay - oneDay);
    let month = parseInt(lastTime.getMonth() + 1);
    let day = lastTime.getDate();
    if (month < 10) {
      month = '0' + month
    }
    if (day < 10) {
      day = '0' + day
    }
    return date.getFullYear() + '-' + month + '-' + day;
  },

  //  /*
  //  * 获取当前的日期
  //  * 返回格式: YYYY-mm-dd
  dateStr(date) {
    date = this.parseDate(date);
    let month = parseInt(date.getMonth() + 1);
    let day = date.getDate();
    if (month < 10) {
      month = '0' + month
    }
    if (day < 10) {
      day = '0' + day
    }
    return date.getFullYear() + '-' + month + '-' + day;
  },

  // 获取时分字符串，格式 hh:mm
  hourStr(date) {
    date = this.parseDate(date);
    let hour = date.getHours() + '';
    let minute = date.getMinutes() + '';
    hour = hour.length === 2 ? hour : '0'+hour;
    minute = minute.length === 2 ? minute : '0'+minute;
    return hour + ':' + minute;
  },

  // 获取日期信息，多个格式一起返回
  dateInfo(date) {
    date = this.parseDate(date);
    const dateStr = this.dateStr(date);
    const hourStr = this.hourStr(date);
    const dateHourStr = dateStr + ' ' + hourStr;
    const longDateStr = this.longDateStr(date);

    return {
      dateStr,
      hourStr,
      dateHourStr,
      longDateStr,
    }
  },

  // 获取星期几
  weekStr(date) {
    date = this.parseDate(date);
    switch (date.getDay()) {
      case 0:
        return '星期天';
      case 1:
        return '星期一';
      case 2:
        return '星期二';
      case 3:
        return '星期三';
      case 4:
        return '星期四';
      case 5:
        return '星期五';
      case 6:
        return '星期六';
    }
  },

  // 获取长日期字符串，格式：m月d日 星期w
  longDateStr(date) {
    date = this.parseDate(date);
    return `${date.getMonth()+1}月${date.getDate()}日 ${this.weekStr(date)}`;
  }
}
