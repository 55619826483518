import Qs from 'qs';

export default {

  getParam(name) {
    let search = location.search || '';
    search = search.substring(1);
    let q = Qs.parse(search);
    if (q[name]) {
      return q[name];
    }
    return '';
  },

}
