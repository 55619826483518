import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '@/views/layout/index.vue'

Vue.use(VueRouter)
//获取原型对象上的push函数
const originalPush = VueRouter.prototype.push
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
export const constantRouterMap = [
  {
    path: '/login',
    name: 'login',
    meta: {
      title: '登录',
      requireAuth: false
    },
    component: () => import( /* webpackChunkName: "login" */ '@/views/login')
  },
  {
    path: '/',
    redirect: '/home',
    component: Layout,
    children: [
      // {
      //   path: '/home',
      //   component: () => import( /* webpackChunkName: "home" */ '@/views/home.vue'),
      //   name: 'home',
      //   meta: { title: '首页' }
      // },

      {
        path: '/home',
        name: 'home',
        meta: {
          title: '首页',
          requireAuth: true,
          keepAlive: true
        },
        component: () => import( /* webpackChunkName: "home" */ '@/views/home'),
      },
      {
        path: '/project',
        name: 'project',
        meta: {
          title: '一级市场-项目库',
          requireAuth: true,
          keepAlive: true
        },
        component: () => import( /* webpackChunkName: "project" */ '@/views/project'),
      },
      {
        path: '/projectSecondaryMarket',
        name: 'projectSecondaryMarket',
        meta: {
          title: '上市公司-项目库',
          requireAuth: true,
          keepAlive: true
        },
        component: () => import( /* webpackChunkName: "projectSecondaryMarket" */ '@/views/projectSecondaryMarket'),
      },
      {
        path: '/projectGovernment',
        name: 'projectGovernment',
        meta: {
          title: '政府平台-项目库',
          requireAuth: true,
          keepAlive: true
        },
        component: () => import( /* webpackChunkName: "projectGovernment" */ '@/views/projectGovernment'),
      },
      {
        path: '/institution',
        name: 'institution',
        meta: {
          title: '财务投资-投资机构管理',
          requireAuth: true,
          keepAlive: true
        },
        component: () => import( /* webpackChunkName: "institution" */ '@/views/institution'),
      },
      {
        path: '/institutionDetail',
        name: 'institutionDetail',
        meta: {
          title: '投资机构详情',
          requireAuth: true,
          keepAlive: true
        },
        component: () => import( /* webpackChunkName: "institutionDetail" */ '@/views/institutionDetail'),
      },
      {
        path: '/investor',
        name: 'investor',
        meta: {
          title: 'VC/PE投资人',
          requireAuth: true,
          keepAlive: true
        },
        component: () => import( /* webpackChunkName: "investor" */ '@/views/investor'),
      },
      {
        path: '/financiaInstitutionInvestors',
        name: 'financiaInstitutionInvestors',
        meta: {
          title: '金融机构投资人',
          requireAuth: true,
          keepAlive: true
        },
        component: () => import( /* webpackChunkName: "financiaInstitutionInvestors" */ '@/views/financiaInstitutionInvestors'),
      },
      {
        path: '/minutesOfMeeting',
        name: 'minutesOfMeeting',
        meta: {
          title: '会议纪要记录',
          requireAuth: true,
          keepAlive: true
        },
        component: () => import( /* webpackChunkName: "minutesOfMeeting" */ '@/views/minutesOfMeeting'),
      },
      {
        path: '/investorDetail',
        name: 'investorDetail',
        meta: {
          title: '投资人详情',
          requireAuth: true,
          keepAlive: true
        },
        component: () => import( /* webpackChunkName: "investorDetail" */ '@/views/investorDetail'),
      },
      {
        path: '/account',
        name: 'account',
        meta: {
          title: '账号管理',
          requireAuth: true,
          keepAlive: true
        },
        component: () => import( /* webpackChunkName: "account" */ '@/views/account'),
      },
      //jurisdiction
      {
        path: '/jurisdiction',
        name: 'jurisdiction',
        meta: {
          title: '角色管理',
          requireAuth: true,
          keepAlive: true,
          roles: ['mgr', 'root']
        },
        component: () => import( /* webpackChunkName: "jurisdiction" */ '@/views/jurisdiction'),
      },
      {
        path: "/processNode",
        name: "processNode",
        meta: {
          title: "流程节点",
          requireAuth: true,
          keepAlive: true
        },
        component: () => import( /* webpackChunkName: "processNode" */ '@/views/processNode'),
      },
      {
        path: "/processLibrary",
        name: "processLibrary",
        meta: {
          title: "流程库",
          requireAuth: true,
          keepAlive: true
        },
        component: () => import( /* webpackChunkName: "processLibrary" */ '@/views/processLibrary'),
      }
      ,
      {
        path: "/assessment",
        name: "assessment",
        meta: {
          title: "评估",
          requireAuth: true,
          keepAlive: true
        },
        component: () => import( /* webpackChunkName: "assessment" */ '@/views/assessment'),
      }, {
        path: "/processManagement",
        name: "processManagement",
        meta: {
          title: "流程进展",
          requireAuth: true,
          keepAlive: true
        },
        component: () => import( /* webpackChunkName: "processManagement" */ '@/views/processManagement'),

      },
      {
        path: "/userCenter",
        name: "userCenter",
        meta: {
          title: "用户中心",
          requireAuth: true,
          keepAlive: true
        },
        component: () => import( /* webpackChunkName: "userCenter" */ '@/views/userCenter'),
      }
      ,
      {
        path: "/questionnaireInvestigation",
        name: "questionnaireInvestigation",
        meta: {
          title: "问卷配置",
          requireAuth: true,
          keepAlive: true
        },
        component: () => import( /* webpackChunkName: "questionnaireInvestigation" */ '@/views/questionnaireInvestigation'),
      }
      ,
      {
        path: "/evaluationCommentary",
        name: "evaluationCommentary",
        meta: {
          title: "测评评语",
          requireAuth: true,
          keepAlive: true
        },
        component: () => import( /* webpackChunkName: "evaluationCommentary" */ '@/views/evaluationCommentary'),
      },
      {
        path: "/acceleratedBusinessRegister",
        name: "acceleratedBusinessRegister",
        meta: {
          title: "加速营报名册",
          requireAuth: true,
          keepAlive: true
        },
        component: () => import( /* webpackChunkName: "acceleratedBusinessRegister" */ '@/views/acceleratedBusinessRegister'),
      },
      {
        path: "/institutionIndustry",
        name: "institutionIndustry",
        meta: {
          title: "产业投资-投资机构管理",
          requireAuth: true,
          keepAlive: true
        },
        component: () => import( /* webpackChunkName: "institutionIndustry" */ '@/views/institutionIndustry'),
      },
      {
        path: "/institutionGovernment",
        name: "institutionGovernment",
        meta: {
          title: "金融机构-投资机构管理",
          requireAuth: true,
          keepAlive: true
        },
        component: () => import( /* webpackChunkName: "institutionGovernment" */ '@/views/institutionGovernment'),
      },
      {
        path: "/myMission",
        name: "myMission",
        meta: {
          title: "我的工作",
          requireAuth: true,
          keepAlive: true
        },
        component: () => import( /* webpackChunkName: "myMission" */ '@/views/myMission'),
      },
      {
        path: "/department",
        name: "department",
        meta: {
          title: "部门管理",
          requireAuth: true,
          keepAlive: true
        },
        component: () => import( /* webpackChunkName: "department" */ '@/views/department'),
      },
      {
        path: "/404",
        name: "notFount",
        meta: {
          title: "页面走丢了",
          requireAuth: false,
          keepAlive: false
        },
        component: () => import( /* webpackChunkName: "404" */ '@/views/404'),
      },
      {
        path: "/dataManagement",
        name: "dataManagement",
        meta: {
          title: "数据管理",
          requireAuth: true,
          keepAlive: true
        },
        component: () => import( /* webpackChunkName: "dataManagement" */ '@/views/dataManagement'),
      },
      {
        path: "/processDocument",
        name: "processDocument",
        meta: {
          title: "流程文件",
          requireAuth: true,
          keepAlive: true
        },
        component: () => import( /* webpackChunkName: "processDocument" */ '@/views/processDocument'),
      },
      {
        path: "/fundCompany",
        name: "fundCompany",
        meta: {
          title: "基金公司",
          requireAuth: true,
          keepAlive: true
        },
        component: () => import( /* webpackChunkName: "fundCompany" */ '@/views/fundCompany'),
      },
      {
        path: "/supplyChainCompany",
        name: "supplyChainCompany",
        meta: {
          title: "供应链公司",
          requireAuth: true,
          keepAlive: true
        },
        component: () => import( /* webpackChunkName: "supplyChainCompany" */ '@/views/supplyChainCompany'),
      },
      {
        path: "/personalCenter",
        name: "personalCenter",
        meta: {
          title: "个人中心",
          requireAuth: true,
          keepAlive: true
        },
        component: () => import( /* webpackChunkName: "personalCenter" */ '@/views/personalCenter'),
      },
      {
        path: "/taskTemplate",
        name: "taskTemplate",
        meta: {
          title: "任务模版",
          requireAuth: true,
          keepAlive: true
        },
        component: () => import( /* webpackChunkName: "taskTemplate" */ '@/views/taskTemplate'),
      },
      {
        path: "/evaluationTemplate",
        name: "evaluationTemplate",
        meta: {
          title: "评估模版",
          requireAuth: true,
          keepAlive: true
        },
        component: () => import( /* webpackChunkName: "evaluationTemplate" */ '@/views/evaluationTemplate'),
      },
      {
        path: "/tbShop",
        name: "onlineShopGoods",
        meta: {
          title: "项目数据监测",
          requireAuth: true,
          keepAlive: true
        },
        component: () => import( /* webpackChunkName: "onlineShopGoods" */ '@/views/onlineShopGoods'),
      },
      {
        path: "/system-setting",
        name: "systemSetting",
        meta: {
          title: "系统设置",
          requireAuth: true,
          keepAlive: true
        },
        component: () => import( /* webpackChunkName: "systemSetting" */ '@/views/systemSetting'),
      },
      {
        path: "/calendar-test",
        name: "calendarTest",
        meta: {
          title: "日历测试",
          requireAuth: false,
          keepAlive: true
        },
        component: () => import( /* webpackChunkName: "calendarTest" */ '@/views/test/calendarTest'),
      },
      {
        path: "/time-calendar-test",
        name: "timeCalendarTest",
        meta: {
          title: "日历组件测试",
          requireAuth: false,
          keepAlive: true
        },
        component: () => import( /* webpackChunkName: "timeCalendarTest" */ '@/views/test/timeCalendarTest'),
      },
      {
        path: "/data-auth",
        name: "dataAuth",
        meta: {
          title: "数据访问",
          requireAuth: true,
          keepAlive: true
        },
        component: () => import( /* webpackChunkName: "dataAuth" */ '@/views/dataAuth'),
      },
      {
        path: "/officeAutomation",
        name: "officeAutomation",
        meta: {
          title: "OA审批",
          requireAuth: true,
          keepAlive: true
        },
        component: () => import( /* webpackChunkName: "officeAutomation" */ '@/views/officeAutomation'),
      },
      {
        path: "/user-preference",
        name: "userPreference",
        meta: {
          title: "用户偏好",
          requireAuth: true,
          keepAlive: true
        },
        component: () => import( /* webpackChunkName: "userPreference" */ '@/views/userPreference'),
      },
      {
        path: "/systemOperationManual",
        name: "systemOperationManual",
        meta: {
          title: "系统操作指南",
          requireAuth: true,
          keepAlive: true
        },
        component: () => import( /* webpackChunkName: "systemOperationManual" */ '@/views/systemOperationManual'),
      },
      {
        path: "/costManagement",
        name: "costManagement",
        meta: {
          title: "费用查询",
          requireAuth: true,
          keepAlive: true
        },
        component: () => import( /* webpackChunkName: "costManagement" */ '@/views/costManagement'),
      }, 
      {
        path: "/costManagementFinancialStaff",
        name: "costManagementFinancialStaff",
        meta: {
          title: "费用预审",
          requireAuth: true,
          keepAlive: true
        },
        component: () => import( /* webpackChunkName: "costManagementFinancialStaff" */ '@/views/costManagementFinancialStaff'),
      }, 
      {
        path: "/costManagementSelfConfirmation",
        name: "costManagementSelfConfirmation",
        meta: {
          title: "费用填报",
          requireAuth: true,
          keepAlive: true
        },
        component: () => import( /* webpackChunkName: "costManagementSelfConfirmation" */ '@/views/costManagementSelfConfirmation'),
      },
      {
        path: "/projectDetails",
        name: "projectDetails",
        meta: {
          title: "一级市场-项目详情",
          requireAuth: true,
          keepAlive: true
        },
        component: () => import( /* webpackChunkName: "projectDetails" */ '@/components/project/projectDetails'),
      },
      {
        path: "/governmentDetails",
        name: "governmentDetails",
        meta: {
          title: "政府平台-项目详情",
          requireAuth: true,
          keepAlive: true 
        },
        component: () => import( /* webpackChunkName: "governmentDetails" */ '@/components/projectGovernment/governmentDetails'),
      }, 
      {
        path: "/businessProcessDetailTab",
        name: "businessProcessDetailTab",
        meta: {
          title: "业务进度表详情",
          requireAuth: true,
          keepAlive: true 
        },
        component: () => import( /* webpackChunkName: "businessProcessDetailTab" */ '@/components/myMission/businessProcess/businessProcessDetailTab'),
      }, 
      {
        path: "/officeOnLine",
        name: "officeOnLine",
        meta: {
          title: "在线预览文档",
          requireAuth: true,
          keepAlive: true 
        },
        component: () => import( /* webpackChunkName: "businessProcessDetailTab" */ '@/views/officeOnLine'),
      },

    ]
  },


];


export default new VueRouter({
  mode: "history",
  routes: constantRouterMap
})
