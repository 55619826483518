<template>
  <div
    class="sidebar-container flexFdcJcs"
    :class="{ 'collapse-width': isCollapse }"
  >
    <div class="top">
      <div
        class="logo"
        @click="reload"
        :class="{ 'collapse-logo': isCollapse }"
      >
        <img src="https://oss.eggxiaoer.com/web/hermes/logo.png" alt="" />
      </div>
      <el-menu
        :default-active="$route.routeAddress"
        class="el-menu-vertical scrollbar"
        background-color="#304156"
        text-color="#bfcbd9"
        active-text-color="#10B9D3"
        style="overflow-x: hidden"
        mode="vertical"
        :collapse="isCollapse"
        unique-opened
        router
      >
        <el-row
          v-for="(item, index) in menuList"
          :key="index"
          :index="String(index)"
        >
          <el-menu-item
            v-show="item.children.length == 0 && item.routeAddress"
            :index="item.routeAddress"
          >
            <i :class="item.icon"></i>
            <span v-show="!isCollapse">{{ item.name }}</span>
          </el-menu-item>
          <el-submenu
            :index="item.id"
            style="text-align: left"
            v-show="item.children.length > 0"
          >
            <template slot="title">
              <i :class="item.icon"></i>
              <span v-show="!isCollapse">{{ item.name }}</span>
            </template>
            <el-menu-item-group>
              <el-menu-item
                class="navChild"
                v-show="child.routeAddress"
                v-for="child in item.children"
                :key="child.id"
                :index="child.routeAddress"
                >{{ child.name }}</el-menu-item
              >
            </el-menu-item-group>
          </el-submenu>
        </el-row>
      </el-menu>
    </div>
    <div class="foot workflowVersion">
      <el-popover
        placement="top-start"
        title="版本 Release Note"
        width="339"
        trigger="hover"
      >
        <div>
          <!-- <el-input
            class="textarea"
            type="textarea"
            :autosize="{ minRows: 1, maxRows: 20 }"
            placeholder=""
            v-model="systemInfo"
            disabled
          >
          </el-input> -->
          <mavon-editor
          class="markdown-body"
            :value="systemInfo"
            defaultOpen="preview"
            :boxShadow="false"
            style="z-index:1;height:50vh"
            :editable="false"
            :subfield="false"
            :toolbarsFlag="false"
          >
          </mavon-editor>
        </div>
        <el-button class="version" type="text" slot="reference">{{
          version
        }}</el-button>
      </el-popover>
    </div>
  </div>
</template>

<script>
import {
  TOKEN_KEY,
  ROLE_CODE_KEY,
  ROLE_CODE_MGR,
  ROLE_CODE_BD,
  ROLE_CODE_IR,
  ROLE_CODE_ROOT,
} from "@/common/constants";
import { mapState } from "vuex";

import { getPermissionInfo } from "@/api/user";
import { deepCopy } from "@/common/deepCopy";
import { getSystemSettingEdition } from "@/api/systemSetting";
import Vue from "vue";
//编辑器 
import { mavonEditor } from 'mavon-editor'
import "mavon-editor/dist/css/index.css"
import 'mavon-editor/dist/markdown/github-markdown.min.css'

export default {
  //   name: "sideBar",

  data() {
    return {
      isCollapse1: true,
      token: false,
      menuList: [],
      activeIndex: null,
      systemInfo: null,
      version: null,
    };
  },
  components:{
    mavonEditor
  },
  created() {
    var hasToken = window.localStorage.getItem(TOKEN_KEY);
    if (!hasToken) {
      this.token = false;
    } else {
      this.token = true;
    }
  },
  mounted() {
    // this.getPermissionInfo();
    this.getSystemSettingEdition();
    this.menuList = JSON.parse(window.localStorage.getItem('menus'))
  },
  activated() {
    this.getSystemSettingEdition();
  },
  computed: {
    ...mapState({
      isCollapse: (state) => state.common.isCollapse,
    }),
  },
  methods: {
    getSystemSettingEdition() {
      getSystemSettingEdition().then((response) => {
        let systemInfo = response.data;
        if(systemInfo){
          this.systemInfo = systemInfo;
          this.version = (systemInfo.split("\n")[0]).slice(1);

        }
      });
    },
    reload() {
      window.location.href = window.location.href;
      window.location.reload;
    },
    getPermissionInfo() {
      getPermissionInfo().then((response) => {
        if (response.success == true) {
          console.log(response.data);
          window.localStorage.setItem(
            "menus",
            JSON.stringify(response.data.menus)
          );
          this.$store.commit("setPermissionInfo", response.data);
          this.menuList = response.data.menus;
        }
      });
    },
    hadnleSelect(index) {
      this.activeIndex = index;
    },
  },
  watch: {
    "$store.state.permissionInfo": function () {
      console.log("this.$store.state.permissionInfo");
      console.log(this.$store.state.permissionInfo);

      if (this.$store.state.permissionInfo.menus.length > 0) {
        this.menuList = this.$store.state.permissionInfo.menus;
      }
    },
    "$store.state.systemInfo": function () {
      if (this.$store.state.systemInfo != this.systemInfo) {
        this.systemInfo = this.$store.state.systemInfo;
        this.version = this.systemInfo.split("\n")[0];
      }
    },

    //
  },
};
</script>

<style lang="scss" scoped>
.el-input {
  color: #000 !important;
  background-color: #bfbfbf !important;
}
.textarea.is-disabled {
  /deep/ .el-textarea__inner {
    background-color: #fff !important;
    border-color: #e4e7ed;
    color: #000 !important;
    cursor: not-allowed;
  }
}
.version {
  color: #bfbfbf;
}
.foot {
  text-align: center;
  margin-bottom: 5px;
  cursor: pointer;
}
.fa {
  margin-right: 5px;
}
.collapse-width {
  width: 64px !important;
}
.collapse-logo {
  font-size: 18px !important;
}

.sidebar-container {
  width: 180px;
  height: 100%;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  transition: width 0.28s;
  z-index: 1001;
  background-color: rgb(48, 65, 86);
  overflow: hidden;
  .top{
    height: 100%;
  }
  .el-menu-vertical:not(.el-menu--collapse) {
    width: 180px;
  }
  .el-menu {
    border: none;
    height: calc(100% - 60px);
    // width: 100% !important;
    overflow-y: auto;
    text-align: left;
  }
  .logo {
    width: 100%;
    height: 60px;
    line-height: 60px;
    font-size: 30px;
    text-align: center;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background-color: rgb(48, 65, 86);

    img {
      width: 80%;
    }
  }
}

.scrollbar::-webkit-scrollbar {
  width: 5px;
  height: 10px;
  /**/
}
.scrollbar::-webkit-scrollbar-track {
  background: rgb(239, 239, 239);
  border-radius: 2px;
}
.scrollbar::-webkit-scrollbar-thumb {
  background: #bfbfbf;
  border-radius: 10px;
}
.scrollbar::-webkit-scrollbar-thumb:hover {
  background: #333;
}
.scrollbar::-webkit-scrollbar-corner {
  background: #179a16;
}
</style>