/*
 * @Description: 
 * @Author: transparent
 * @Date: 2021-12-31 11:17:14
 * @LastEditTime: 2022-02-09 16:12:45
 * @LastEditors: transparent
 * @FilePath: /hermes-web/src/api/systemSetting.js
 */
import request from '@/utils/request'

export function getSystemSetting(key) {
  return request({
    url: '/admin/system-setting/get?key=' + key,
    method: 'get'
  });
}

export function updateSystemSetting(data) {
  return request({
    url: '/admin/system-setting/update',
    method: 'post',
    data: data
  });
}

export function regrant(target) {
  return request({
    url: '/admin/system-setting/data-regrant?target=' + target,
    method: 'post'
  });
}

export function getSystemSettingEdition() {
  return request({
    url:  '/system/setting/get?key=system_setting_release_notes',
    method: 'get'
  });
}

export function updateSystemSettingEdition(data) {
  return request({
    url: '/system/setting/update',
    method: 'post',
    data: data
  });
}