<!--
 * @Description: 
 * @Author: transparent
 * @Date: 2022-01-05 14:01:36
 * @LastEditTime: 2022-03-09 14:49:56
 * @LastEditors: transparent
 * @FilePath: /hermes-web/src/views/layout/index.vue
-->
<template>
  <div>
    <SideBar></SideBar>
    <div class="right-container" :class="{ 'collapse-ml': isCollapse }">
      <TopBar></TopBar>
      <AppMain></AppMain>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import SideBar from "./sideBar";
import TopBar from "./TopBar/index";
import AppMain from "./appMain";
export default {
  data() {
    return {
      no: true,
      have: false,
    };
  },
  computed: {
    ...mapState({
      isCollapse: (state) => state.common.isCollapse,
    }),
  },
  components: {
    SideBar,
    TopBar,
    AppMain,
  },
  activated(){
    console.log("passwordExpiresInChange")

  },
  created() { 
    // //在页面加载时读取里的状态信息
    // if (window.localStorage.getItem("store")) {
    //   this.$store.replaceState(
    //     Object.assign(
    //       {},
    //       this.$store.state,
    //       JSON.parse(window.localStorage.getItem("store"))
    //     )
    //   );
    // }

    // //在页面刷新时将vuex里的信息保存到localStorage里
    // window.addEventListener("beforeunload", () => {
    //   console.log(this.$store.state);
    //   window.localStorage.setItem("store", JSON.stringify(this.$store.state));
    // });
  },
  watch: {
    $route() {
      if (this.$route.name === "login") {
        this.no = true;
        this.have = false;
      } else {
        this.no = false;
        this.have = true;
        
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.right-container {
  width: calc(100% - 180px);
  height: 100%;
  margin-left: 180px;
  transition: 0.28s;
}
.collapse-ml {
  width: calc(100% - 64px);
  margin-left: 64px;
} 
</style>