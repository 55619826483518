import Vue from 'vue';

Vue.mixin({

  methods: {

  },

  filters: {
    transMeetingType(type) {
      switch (type) {
        case 'other':
          return '其它会议';
        case 'schedule':
          return '日程会议';
        case 'invest':
          return '投资人会议';
        case 'project':
          return '项目会议';
      }
    }
  }

});

