<!--
 * @Description: 
 * @Author: transparent
 * @Date: 2021-03-07 11:07:58
 * @LastEditTime: 2021-12-01 14:31:26
 * @LastEditors: transparent
 * @FilePath: /hermes-web/src/App.vue
-->
<template>
  <router-view />
</template>

<script>
export default {
  name: "app",
  components: {},
  data() {
    return {};
  },
  created() {},
  mounted() {
  },
  watch: {},
};
</script>

<style>
html {
  /* overflow-x: hidden; */
  padding: 0;
  margin: 0;
  -ms-touch-action: none;
  width: 100%;
  height: 100%;
}

body {
  margin: 0 auto !important;
  /* overflow-x: hidden; */
  width: 100%;
  height: 100%;
}

#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  width: 100%;
  height: 100%;
}

.notoken {
  background-image: url("https://eggwaiter.oss-cn-beijing.aliyuncs.com/web/hermes/bg.jpg");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  -moz-background-size: 100% 100%;
}
.no {
  /* width: 100%;
  height: 100vh; */
}
</style>
