const common = {
  state: {
    isCollapse: false,
    lockScroll: false,
  },
  mutations: {
    // 折叠侧边栏
    TOGGLE_SIDEBAR(state) {
      state.isCollapse = !state.isCollapse;
    },

    LOCK_SCROLL(state, value) {
      state.lockScroll = value;
    },

  },
  actions: {
    toggleSidebar({commit}) {
      commit('TOGGLE_SIDEBAR');
    },

    lockScroll({commit}, payload) {
      commit('LOCK_SCROLL', payload.value);
    }
  }
}

export default common
